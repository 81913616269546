import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';

const Articles = ({ data: { allContentfulArticle }}) => (
  <Layout>
    <div>
      {allContentfulArticle.edges.map(({ node: article }) => (
        <div key={article.id}>
          <h2>FERES Articles</h2>
          <Link to={`articles/${article.slug}`}>
            <h3>{article.title}</h3>
          </Link>
          <span>{article.publishDate}</span>
          <Img
            style={{ maxWidth: 200 }}
            fluid={article.heroImage.fluid}
          />
          <p>{article.description}</p>
          <span>by {article.author.name}</span>
        </div>
      ))}
    </div>
  </Layout>
);

export const query = graphql`
{
  allContentfulArticle {
    edges {
      node {
        id
        slug
        description
        title
        publishDate(formatString:"MMMM Do, YYYY")
        author {
          name
        }
        seoPageTitle
        heroImage {
          fluid(maxWidth: 200) {
             ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
    }
  }
}
`;

export default Articles;